import * as React from 'react';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Form } from '../components/Form';
import { Breadcrumbs } from '../components/Breadcrumbs';

import logo from '../images/gisp-logo.svg';
import { Button } from '../components/Button';
import { Seo } from '../components/Seo';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, 'ГИСП'],
                ]}
            />
            <div className="container section-margin">
                <Title className="h1" level="h1">
                    ГИСП
                </Title>
                <div className="grid-gisp">
                    <div className="gisp-content">
                        <p>
                            ГИСП - государственная информационная система промышленности. Основная цель – это создание и
                            развитие условий для взаимодействия между собой субъектов промышленности. На базе этой
                            системы создается ГИСП каталог услуг, технологий и производителей.
                        </p>
                        <p>
                            ГИСП стал важным инструментом в реализации Федерального закона - «О промышленной политике в
                            Российской Федерации от 31.12.2014 N 488-ФЗ». Система позволяет отслеживать промышленные
                            проекты, и открывает новые возможности для бизнеса.
                        </p>
                        <p>
                            ГИСП дает возможность эффективного планирования, мониторинга и реализации промышленной
                            политики в стране.
                        </p>
                        <p>
                            В ГИСП активно работает структурированный каталог продукции по разным сферам закупок:
                            радиоэлектронике, автотранспорту, промышленному и медицинскому оборудованию. Новый единый
                            каталог позволяет заказчику намного быстрее провести поиск товаров по ИНН, коду ОКПД2,
                            региону и отрасли для соблюдения норм нацрежима и квот.
                        </p>
                        <p>
                            Единый каталог ГИСП – каталог товаров, производителей и услуг – первый в нашей стране
                            электронный представитель всех предложений российской промышленности. Для реализации проекта
                            используют методы: автоматизация сбора данных в систему; автоматическая обработка собранной
                            информации; поиск поставщиков и заказчиков по определенным видам продукции в разных отраслях
                            отечественной промышленности; информирование субъектов промышленности о мерах
                            государственной поддержки.
                        </p>
                        <p>
                            Система дает дополнительные цифровые возможности для развития отечественного производства,
                            укрепления связей между его субъектами. Она представляет собой единую цифровую платформу,
                            место, где собрана и структурирована информация о текущем состоянии промышленности в стране,
                            объемах производства и прогнозах развития.
                        </p>
                    </div>
                    <div className="gisp-link">
                        <img src={logo} alt="ГИСП" />
                        <Button link="https://passport.gisp.gov.ru/adfs/ls/" target="_blank">
                            Зарегистрироваться
                        </Button>
                    </div>
                </div>
            </div>

            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo
            title="Гисп"
            description="ГИСП - государственная информационная система промышленности. Основная цель – это создание и
                            развитие условий для взаимодействия между собой субъектов промышленности."
        />
    );
}
